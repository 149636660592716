const terms = {
  lastUpdate: '29 de agosto de 2023',
  info:
    'A RESALE TECNOLOGIA E SERVIÇOS SA., empresa inscrita no CNPJ/MF sob o nº 21.129.873/0001-79, com sede na Av. Independência, 950, sala 22/24 - Alemães - Piracicaba - SP, 13419-155 (de agora em adiante, a “Resale”), neste ato informa os “Termos e Condições de Uso” que regem o uso: (i) do seu site, disponível em www.emgeaimoveis.com.br (de agora em adiante, o “Site”); e (ii) da plataforma Pagimóvel®, disponível no Site após cadastro para criação de login e senha (de agora em diante, a “Plataforma”). Por favor, solicitamos que leia atentamente as condições abaixo estipuladas, uma vez que, ao visitar o Site e/ou ao utilizar a Plataforma, você estará aceitando tacitamente as cláusulas e condições dos Termos e Condições de Uso da Resale que se encontrem em vigor na data do acesso e, portanto, devendo verificar os mesmos com frequência e/ou a cada vez que o acessar.',
  terms: [
    {
      title: '1 - Considerações Iniciais',
      descriptions: [
        '1.1 - A Resale é a proprietária e única titular dos direitos sobre: (i) o Site e seus subdomínios, bem como sobre os aplicativos, softwares, plug-ins e demais informações a ele relacionadas; e (ii) a Plataforma, bem como sobre todas as informações e funcionalidade ali contidas.',
        '1.2 - A atuação da Resale, observados estes Termos e Condições de Uso, se restringe à disponibilização de informações referentes à imóveis disponibilizados para venda por instituições financeiras ou empresas do setor imobiliário, mediante contrato prévio para divulgação dos mesmos (de agora em diante, os “VENDEDORES”), por meio do Site e da Plataforma.',
        '1.3 - A Resale tem a prerrogativa de alterar a qualquer tempo as configurações e informações destes Termos e Condições de Uso, sem necessidade de qualquer prévia notificação, sendo que a utilização do Site e/ou da Plataforma, após a realização de tais mudanças, acarretará na automática aceitação do usuário aos seus novos termos.',
        '1.4 - Caso você não concorde com estes Termos e Condições de Uso, recomendamos que não prossiga com a navegação no Site, com o cadastramento na Plataforma, bem como se abstenha de acessá-la e utilizá-la.',
        '1.5 - No acesso, navegação, cadastro e/ou utilização da Plataforma, aplicam-se as disposições constantes na Política de Privacidade conjuntamente com estes Termos e Condições de Uso.',
        '1.6 - Ao acessar e se cadastrar na Plataforma, você declara ter no mínimo 18 (dezoito) anos, bem como atesta ser civilmente capaz para compreender, aceitar e cumprir estes Termos e Condições de Uso e a Política de Privacidade editada pela Resale.',
        '1.7 - Caso reste alguma dúvida, após a leitura destes Termos e Condições de Uso, entre em contato conosco, através do site www.resale.com.br, na aba “Fale Conosco”, ou nos contate por meio do endereço de e-mail naoresponda@resale.com.br.',
      ],
    },
    {
      title: '2 - Do Acesso e Uso do Site e da Plataforma',
      descriptions: [
        '2.1 - Estes Termos e Condições de Uso estabelecem o conjunto de deveres, obrigações e condições que os usuários devem seguir ao acessar e/ou utilizar o Site e a Plataforma. O acesso do usuário ao Site e/ou à Plataforma implica na irrestrita concordância de todas as condições estabelecidas nestes Termos e Condições de Uso.',
        '2.2 - É vedado ao usuário modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou criar obras derivadas a partir das informações acessadas no Site e/ou na Plataforma, bem como transferir ou utilizar para fins comerciais tais informações, aplicativos, softwares, produtos ou serviços, sob pena de violação do presente termo e infração legal.',
        '2.3 - O Site e a Plataforma estão disponíveis apenas para uso pessoal e não comercial do usuário. Portanto, não é permitido vender, fazer links, usar, copiar, monitorar (ex. spider, scrap), exibir, baixar ou reproduzir qualquer conteúdo ou informação, software, reservas, tickets, passes, produtos ou serviços ali disponíveis para quaisquer atividades de propósito comercial ou competitivo.',
      ],
    },
    {
      title: '3 - Dos Imóveis',
      descriptions: [
        '3.1 - O site da RESALE divulga informações acerca de imóveis a ela disponibilizados pelos VENDEDORES.',
        '3.2 - As informações constantes no site são disponibilizadas em caráter meramente informativo para o usuário que deseja realizar sua pesquisa.',
        '3.3 - As fotos dos imóveis divulgadas no site têm caráter meramente ilustrativo e não podem ser consideradas atualizadas, fato esse que exige que qualquer proposta ou negociação somente seja realizada mediante visita prévia aos mesmos.',
        '3.4 - As informações disponibilizadas pelos VENDEDORES podem sofrer mudanças a qualquer momento, não sendo garantida a precisão e veracidade destes dados, não se responsabilizando a RESALE pela sua veracidade e atualização.',
      ],
    },
    {
      title: '4 - Da Consolidação dos Negócios',
      descriptions: [
        '4.1 - Tendo em vista que as informações disponíveis no Site são meramente informativas, toda e qualquer negociação, relação de intermediação e/ou de compra e venda dos imóveis dos VENDEDORES, serão regidas e consolidadas por meio da Plataforma, mediante a celebração de contratos específicos entre as partes envolvidas.',
        '4.2 - A confirmação das informações disponíveis no Site, a diligência jurídica para a negociação e a análise das condições físicas do imóvel são de responsabilidade do Comprador, antes da compra, conforme seu juízo e conveniência para e avaliação do negócio e seus riscos. Recomenda-se, inclusive, a contratação de profissionais especializados que poderão oferecer a informação necessária sobre os impactos de tais pendências em sua aquisição ou para uma análise completa da situação do Imóvel e riscos envolvendo sua aquisição.',
      ],
    },
    {
      title:
        '5 - Do processo de compra pela Plataforma e coibição de práticas anti comerciais ',
      descriptions: [
        '5.1 - O regramento do processo de compra via Plataforma pode variar de acordo com a modalidade de venda e/ou com o VENDEDOR, sendo que essas e outras informações serão indicadas diretamente na Plataforma e ou em seus instrumentos, na Ficha do Imóvel, assim como, quaisquer modificações nas políticas ou metodologia das mesmas.',
        '5.2 - Todas as propostas submetidas à Plataforma seguirão para análise do VENDEDOR dos imóveis somente após estarem completas, isto é, com todos os documentos obrigatórios conforme políticas definidas por estes.',
        '5.3 - Caso apurado que, quando do envio de propostas, exista qualquer indício de artifícios de manipulação, artifício tecnológico de compra com automação, suspeita de algum tipo de fraude ou mesmo inércia ou desídia injustificada no processo de compra já realizado na Plataforma, antes do bloqueio do imóvel para o recebimento de novas propostas e do envio para análise do VENDEDOR, será exigida a comprovação do pagamento dos honorários previstos na Ficha Proposta, como forma de manifestação real de interesse e capacidade de compra.',
        '5.4 - Existindo comprovada reclamação em órgãos oficiais, sites, plataformas de reclamações, processos administrativos ou judiciais envolvendo o Comprador e a Plataforma, de forma reincidente, sem fundamentos plausíveis, o Comprador ficará suspenso da realização de novas propostas até a conclusão e apuração do fato concreto.',
        '5.5 - Caso seja reincidente por mais de três vezes no caso de manipulação, artifício tecnológico de compra com automação, suspeita de fraude, inércia ou desídia injustificada ou mesmo diversas reclamações a propositura de processos judiciais e ou administrativos, o Comprador poderá ficar suspenso de novos participar dos processos de compra de imóveis na Plataforma, pelo prazo de 06 meses.',
        '5.6 - O cliente suspenso poderá entrar em contato com a Plataforma, para apresentação de esclarecimentos e solicitação da reativação do cadastro para participar da realização de novas propostas junto à Plataforma, estando o seu pedido sujeito à apreciação do comitê interno da Plataforma.',
        '5.7 - Sem prejuízo, outras penalidades podem ser aplicadas em conformidade com a legislação vigente.',
        '5.8 - A aplicação de tais procedimentos administrativos tem como objetivo manter a integridade e a livre competição do processo de compra e venda de imóveis pela Plataforma, preservando os VENDEDORES e, principalmente, os Compradores de boa-fé, além de coibir práticas anti comerciais, lavagem de dinheiro, entre outras.',
      ],
    },
    {
      title: '6 - Da Responsabilidade',
      descriptions: [
        '6.1 - A Resale não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção do acesso ao Site e/ou à Plataforma ou falhas no seu funcionamento.',
        '6.2 - A Resale não poderá ser responsabilizada, em hipótese algo, por quaisquer danos diretos ou indiretos como consequência de quaisquer fatos resultantes do uso do Site e/ou da Plataforma, suas ferramentas, aplicativos, softwares e plug-ins ou da inabilidade de uso destes, ou ainda por quaisquer informações, produtos ou serviços obtidos através dele.',
        '6.3 - A Resale utiliza as melhores práticas recomendadas de mercado para manter seguros todos os dados inseridos no Site na Plataforma, entretanto, a Resale se exime de responsabilidade por eventuais danos e prejuízos de toda natureza que decorram do conhecimento que terceiros não autorizados tenham de quaisquer informações passadas pelo usuário, em decorrência de falha exclusivamente atribuível ao usuário e/ou à terceiros que fujam a qualquer controle razoável da Resale.',
        '6.4 - A Resale não garante a ausência de softwares maliciosos quando da utilização do Site e/ou da Plataforma, bem como outros elementos nocivos que possam produzir alterações nos sistemas informáticos dos usuários (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer da presença de vírus ou de outros elementos nocivos no Site e/ou Plataforma.',
      ],
    },
    {
      title: '7 - Links a Outros Sites',
      descriptions: [
        '7.1 - A RESALE pode fornecer links a sites de terceiros. A presença de links para outros sites não constitui um endosso do conteúdo nem das práticas de negócios dessas entidades.',
        '7.2 - Por esta razão, nenhuma responsabilidade, de nenhuma espécie, seja ela de acessibilidade, veracidade, legalidade do conteúdo ou outra qualquer, poderá ser imputada à RESALE quando o usuário, por meio do site, adentrar em outros sites de propriedade, administração e/ou controle de terceiros, os quais serão os únicos e exclusivos responsáveis.',
      ],
    },
    {
      title: '8 - Informações Úteis',
      descriptions: [
        '8.1 - De forma a otimizar a experiência dos usuários do Site e da Plataforma a Resale disponibiliza conteúdos, matérias e informações que podem lhes interessar, tudo isto por do blog da Resale, disponível em www.blog.resale.com.br.',
      ],
    },
    {
      title: '9 - Da Política de Privacidade',
      descriptions: [
        '9.1 - A RESALE tomará todas as medidas possíveis para prover segurança e sigilo das informações que capta. Contudo, para que as medidas adotadas tenham eficácia, faz-se necessário que cada usuário também tenha atitude responsável sempre que acessar a internet, principalmente em relação aos dados de sua identificação individual.',
        '9.2 - As informações capturadas por meio do site e suas ferramentas são utilizadas pela RESALE com a finalidade de: (i) permitir ao usuário navegar no site; (ii) identificar o perfil, desejos ou necessidades dos usuários; e (iii) realizar estatísticas e pesquisas genéricas.',
        '9.3 - Um cookie, que consiste em um arquivo com dados enviados ao computador do usuário, poderá ser inserido em determinadas áreas do site e serão utilizados em programação exclusivamente para a determinação de padrões de utilização.',
      ],
    },
    {
      title: '10 - Disposições Gerais',
      descriptions: [
        '10.1 - O descumprimento pelo usuário das cláusulas, condições e obrigações constantes neste termo enseja à RESALE o direito de cancelar, suspender, excluir e/ou desativar o acesso, o cadastro ou a utilização do site pelo usuário, por meio de qualquer ferramenta disponível, de forma temporária ou definitiva, ao seu único e exclusivo critério, sem prejuízo da adoção de todas as medidas legais pertinentes e sem a necessidade de aviso prévio.',
        '10.2 - A não validade, no todo ou em parte, de qualquer disposição deste Termo não afetará a validade de qualquer outra disposição deste, ficando a RESALE autorizada a substituir a disposição declarada nula por outra que reflita a sua real intenção quando da elaboração deste Termo.',
      ],
    },
    {
      title: '11 - Do Foro',
      descriptions: [
        '11.1 - Os presentes Termos e Condições de Uso serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro de São Paulo/SP, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. Você consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.',
      ],
    },
  ],
}

const privacy = {
  lastUpdate: '29 de agosto de 2023',
  info:
    'Nós da RESALE TECNOLOGIA E SERVIÇOS SA., empresa inscrita no CNPJ/MF sob o nº 21.129.873/0001-79, com sede na Av. Independência, 950, sala 22/24 - Alemães - Piracicaba - SP, 13419-155 (de agora em adiante, a “Resale”), preocupados com a sua privacidade e com o objetivo de trazer transparência para nossas atividades, editamos a presente Política de Privacidade (de agora em diante, a “Política”) para informar a você: (i) visitante do seu site, disponível em www.seuimovelbb.com.br (de agora em adiante, o “Site”); e/ou (ii) usuário da plataforma Pagimóvel®, disponível no Site após cadastro para criação de login e senha (de agora em diante, a “Plataforma”), sobre quais informações suas são coletadas, além de como elas são tratadas, em que situações são compartilhadas e quando são excluídas, nos termos a seguir:',
  terms: [
    {
      title: '1 - Informações Coletadas',
      descriptions: [
        '1.1 - A Resale coleta todas as informações inseridas por você, visitante do Site e/ou usuário da Plataforma, tais como dados cadastrais, avaliações, comentários, eventual participação em pesquisas e enquetes, dentre outros. Em síntese, são coletadas pela Resale todas as informações ativamente disponibilizadas por você na navegação pelo Site e/ou na utilização da Plataforma, normalmente destinadas à melhoria de seu sistema e do modo de prestação de serviços.',
        '1.2 - A Resale também coleta algumas informações, automaticamente, quando você acessa e navega pelo Site e/ou utiliza a Plataforma, tais como características do dispositivo de acesso, do navegador, Protocolo de Internet (IP, com data e hora), origem do IP, informações sobre cliques, páginas acessadas, as páginas seguintes acessadas após a saída do Site e/ou da Plataforma, ou qualquer termo de busca ali digitado, dentre outros. A Resale também poderá utilizar algumas tecnologias padrões para coletar informações sobre você, tais como cookies, pixel tags, beacons e local shared objects, de modo a melhorar sua experiência de navegação.',
        '1.3 - Você pode, a qualquer momento, bloquear algumas destas tecnologias para coleta automática de dados. No entanto, caso essa configuração seja implementada, é possível que algumas das funções oferecidas pelo Site e/ou pela Plataforma deixem de funcionar corretamente.',
        '1.4 - Desta forma, ciente das informações coletadas por meio do Site e/ou da Plataforma, você manifesta o seu consentimento livre, expresso e informado com relação à coleta de tais informações, para fins do disposto no artigo 7º, inciso IX, da Lei 12.965/2014.',
      ],
    },
    {
      title: '2 - Utilização e Tratamento das Informações',
      descriptions: [
        '2.1 - A Resale considera todas as informações coletadas por meio do Site e/ou da Plataforma como confidenciais. Portanto, somente as utilizará da forma aqui descrita e por você autorizada.',
        '2.2 - Todas as informações cadastradas e coletadas no Site e/ou na Plataforma são utilizadas para a prestação de serviços pela Resale, para melhorar sua experiência de navegação e utilização, bem como para fins publicitários.',
        '2.3 - A Resale poderá trabalhar com empresas terceirizadas para a divulgação de anúncios à você durante seu acesso ao Site e/ou à Plataforma. Tais empresas poderão coletar informações sobre suas visitas, no intuito de fornecer anúncios personalizados sobre bens e serviços do seu interesse. Tais informações não incluem nem incluirão nome, endereço, e-mail ou seu número de telefone.',
        '2.4 - Você manifesta o seu consentimento livre, expresso e informado para que a Resale e seus parceiros utilizem as informações coletadas por meio do Site e/ou da Plataforma para fins publicitários, bem como para adequada prestação de serviços pela Resale, para fins do disposto no artigo 7º, inciso IX da Lei 12.965/2014.',
        '2.5 - Se Você não desejar receber mais e-mails promocionais, seguir as orientações constantes ao final da mensagem, a fim de viabilizar o descadastramento.',
        '2.6 - Ainda, importante atentar que o Site e/ou a Plataforma podem conter links para outras páginas, inclusive de parceiros, que possuem políticas de privacidade com previsões diversas do disposto nesta Política da Resale. Dessa forma, a Resale não se responsabiliza pela coleta, utilização, compartilhamento e armazenamento de seus dados pelos responsáveis por tais páginas fora de sua plataforma.',
      ],
    },
    {
      title: '3 - Compartilhamento de Informações',
      descriptions: [
        '3.1 - A Resale poderá compartilhar os dados coletados por meio do Site e/ou da Plataforma, nas seguintes situações: (i) com empresas parceiras da Resale, para fins publicitários, conforme descrito no item “2”, supra; (ii) quando necessário às atividades comerciais da Resale; (iii) para proteção dos interesses da Resale em qualquer tipo de conflito, incluindo ações judiciais; (iv) no caso de transações e alterações societárias envolvendo a Resale, hipótese em que a transferência das informações será necessária para a continuidade dos serviços; e/ou (v) mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.',
      ],
    },
    {
      title: '4 - Armazenamento das Informações',
      descriptions: [
        '4.1 - As informações fornecidas por você na Plataforma serão armazenadas pela Resale, em servidores próprios ou por ele contratados nacional, ou internacionalmente.',
        '4.2 - A Resale emprega todos os esforços razoáveis de mercado para garantir a segurança de seus sistemas na guarda de referidos dados, tais como: (i) utilização de métodos padrões e de mercado para criptografar e anonimizar os dados coletados; (ii) utilização de software de proteção contra acesso não autorizado aos nossos sistemas; (iii) autorização de acesso somente a pessoas previamente estabelecidas aos locais onde armazenamos as informações; (iv) existência de políticas internas para a manutenção da segurança da informação; e (v) celebração de contratos com os colaboradores que têm acesso às suas informações, visando estabelecer a obrigação de manutenção do sigilo absoluto e confidencialidade dos dados acessados, sob pena de responsabilidade civil e penal, nos moldes da legislação brasileira.',
        '4.3 - Esta Política representa esforço da Resale no sentido de resguardar suas informações. No entanto, em razão da própria natureza da Internet, não é possível garantir que terceiros mal-intencionados não tenham sucesso em acessar indevidamente as informações obtidas pela Resale.',
      ],
    },
    {
      title: '5 - Exclusão das Informações',
      descriptions: [
        '5.1 - Você pode solicitar a Resale que as informações referidas na presente Política sejam excluídas, por meio da solicitação de descadastramento a ser enviada ao endereço de e-mail naoresponda@resale.com.br com o assunto “Exclusão de Informações”.',
        '5.2 - A Resale empreenderá os melhores esforços para atender a todos os pedidos de exclusão, no menor espaço de tempo possível. Tal exclusão, no entanto, removerá também o seu cadastro do Site e da Plataforma, impossibilitando novos acessos.',
        '5.3 - A Resale esclarece a você que respeitará o prazo de armazenamento mínimo de determinadas informações, conforme determinado pela legislação brasileira, ainda que você solicite a exclusão de tais informações.',
      ],
    },
    {
      title: '6 - Aceitação',
      descriptions: [
        '6.1 - A aceitação desta Política por você se dará no ato do seu clique no botão “Aceito” ao se cadastrar na Plataforma e, em relação ao Visitante, quando faz a navegação no Site.',
        '6.2 - Você concorda e permite o acesso às suas informações a partir da sua navegação no Site e/ou do seu cadastro na Plataforma, manifestando consentimento livre, expresso e informado, nos termos do artigo 43 do Código de Defesa do Consumidor e artigo 7º, inciso IX, da Lei 12.965/2014. 6.2. Caso Você não concorde com a presente Política, recomendamos que não prossiga com o cadastramento na Plataforma e/ou se abstenha de acessá-la e utilizá-la.',
        '6.3 - No acesso, navegação do Site e/ou cadastro e utilização da Plataforma, aplicam-se as disposições constantes nos Termos e Condições de Uso da Plataforma, conjuntamente com esta Política.',
      ],
    },
    {
      title: '7 - Alteração e atualização desta política',
      descriptions: [
        '7.1 - A Resale se reserva o direito de alterar essa Política de Privacidade sempre que necessário, com o fito de fornecer maior segurança e praticidade a você, assim como para cumprir a legislação aplicável, o que poderá se dar sem prévia notificação, salvo em caso de expressa vedação legal. Por isso, é importante que você leia esta Política a cada nova atualização, com a observância da data de modificação informada ao final deste documento.',
      ],
    },
    {
      title: '8 - Dúvidas e Contato',
      descriptions: [
        '8.1 - Caso reste alguma dúvida, após a leitura desta Política de Privacidade, entre em contato conosco, através do site www.resale.com.br, na aba “Fale Conosco” ou nos contate por meio do endereço de e-mail naoresponda@resale.com.br.',
      ],
    },
    {
      title: '9 - Legislação e Foro Competente',
      descriptions: [
        '9.1 - Essa Política de Privacidade será regida, interpretada e executada de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com as de outros estados ou países.',
        '9.2 - Fica eleito o Foro da Comarca da cidade de São Paulo/SP, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. Você consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.',
      ],
    },
  ],
}

const cookies = {
  lastUpdate: '29 de agosto de 2023',
  terms: [
    {
      title: '1 - Política de Cookies',
      descriptions: [
        '1.1 - O site www.seuimovelbb.com.br (de agora em adiante, o “Site”) é de propriedade da RESALE TECNOLOGIA E SERVIÇOS SA., empresa inscrita no CNPJ/MF sob o nº 21.129.873/0001-79, com sede na Av. Independência, 950, sala 22/24 - Alemães - Piracicaba - SP, 13419-155 (de agora em adiante, a “Resale”).',
        '1.2 - Informamos que o nosso Site utiliza a tecnologia denominada “Cookies” com o objetivo de melhorar a experiência de navegação dos seus usuários, mostrando informações que sejam de seu interesse sempre que acessem o Site, bem como para melhorar a qualidade dos nossos serviços.',
      ],
    },
    {
      title: '2 - O que são Cookies?',
      descriptions: [
        '2.1 - Cookies são arquivos ou informações que podem ser armazenadas no seu dispositivo com acesso à internet (computadores, smartphones, tablets, entre outros) quando você acessa páginas web, com o objetivo de fornecer informações que poderão ser atualizadas e coletadas pela entidade responsável pela sua instalação, neste caso a Resale.',
        '2.2 - As informações coletadas por meio dos Cookies podem incluir a data e hora de acesso, o tempo de conexão, o lugar do acesso, o tipo de dispositivo utilizado, o tipo de navegador, os sites visitados (antes e depois do acesso ao Site), número de cliques e outros dados referentes ao comportamento do usuário na Internet.',
        '2.3 - Os Cookies em geral contêm o nome da página web que o originou, por quanto tempo estes permanecerão armazenados no seu dispositivo e um número específico que normalmente é gerado aleatoriamente, o qual permite identificar univocamente o computador do usuário, ainda que este mude de localização ou de endereço IP.',
      ],
    },
    {
      title: '3 - Cookies recolhem meus dados pessoais?',
      descriptions: [
        '3.1 - A tecnologia de Cookies não coleta informações que permitem tornar o usuário pessoalmente identificável. Os Cookies podem ser utilizados para identificar o usuário como um visitante único, ao mesmo tempo em que este permanece anônimo.',
      ],
    },
    {
      title: '4 - Que tipo de cookies utilizamos?',
      descriptions: [
        '4.1 - Nosso Site poderá utilizar qualquer dos tipos de Cookies descritos abaixo:',
        'Cookies de Sessão: Estes Cookies são utilizados para coletar e armazenar dados enquanto o usuário acessa uma página web, permanecendo no dispositivo até que usuário feche o navegador ou até o momento em que estes sejam excluídos automaticamente. Os Cookies de Sessão possuem as seguintes características principais:',
        'I. Manter o usuário identificado, de forma que, se este encerra um serviço oferecido pela página web, o navegador ou o dispositivo e, em outro dia o usuário volta a acessar a página web, continuará identificado, assim facilitando a sua navegação, evitando a necessidade de que volte a se identificar;',
        'II. Esta função pode ser desabilitada se o usuário clica na função “fechar sessão”, de forma que este Cookie se elimina e a próxima vez que este volte a acessar os serviços oferecidos pela página web terá que iniciar uma nova sessão para se identificar;',
        'III. Comprovar se o usuário está autorizado a acessar certos serviços oferecidos pela página web, como por exemplo, participar de alguma promoção, concurso, evento, entre outros;',
        'IV. Adicionalmente, alguns serviços oferecidos pela página web podem utilizar conexões com redes sociais como o Facebook, Twitter, Instagram e YouTube. Quando o usuário se registra em um serviço oferecido pela página web com credenciais de uma rede social, este autoriza que tal rede social guarde um Cookie Persistente que recorda a sua identidade e garante o acesso aos serviços até que a Cookie seja excluído ou expire. O usuário pode deletar este Cookie e revogar o acesso aos serviços por meio da rede social, atualizando as suas respectivas definições e configurações.',
        'Cookies de Geolocalização: São Cookies utilizados para averiguar em que país o usuário se encontra quando acessa um serviço oferecido por uma página web. Estes Cookies são totalmente anônimos e apenas são utilizados para ajudar a orientar a veiculação de conteúdos de acordo com a localização do usuário.',
        'Cookies Persistentes: São Cookies utilizados para armazenar dados no dispositivo do usuário, os quais podem ser acessados pela entidade responsável pela sua instalação, tendo um prazo de validade que pode variar de alguns minutos até vários anos.',
        'Cookies Técnicos: São Cookies que permitem a navegação por meio de uma página web, plataforma ou aplicativo e a utilização das diferentes funções ou serviços ali oferecidos, como por exemplo: controlar o tráfego e comunicação de dados, identificar a sessão, conceder acesso à áreas restritas, recordar elementos que integram um pedido, realizar o processo de compra de um pedido, realizar a solicitação de inscrição ou participação em um evento, utilizar elementos de segurança durante a navegação, armazenar conteúdos para a difusão de mídias ou compartilhar conteúdos por meio das redes sociais.',
        'Cookies de Personalização: São Cookies que permitem ao usuário acessar serviços que possuam algumas características pré-definidas em função de uma série de critérios/preferências no seu dispositivo, como por exemplo: o idioma, o tipo de navegador utilizado, a configuração regional desde onde se realiza o acesso, entre outras.',
        'Cookies de Análise: São Cookies que permitem à entidade responsável pela sua instalação, o acompanhamento e análise de comportamento do usuário nas páginas web. A informação coletada por meio deste tipo de Cookie é utilizada na análise da atividade das páginas web, plataforma ou aplicativo e para a elaboração de perfis de navegação dos seus usuários, com o objetivo de introduzir melhorias em função da análise dos dados de uso.',
        'Cookies Publicitários: São Cookies que permitem a gestão, da forma mais eficiente possível, dos espaços publicitários que tenham sido incluídos em uma página web, plataforma ou aplicativo, com base em critérios como o conteúdo ou a frequência com que se mostram os anúncios.',
        'Cookies de Publicidade Comportamental: São Cookies que permitem a gestão, da forma mais eficiente possível, dos espaços publicitários que tenham sido incluídos em uma página web, plataforma ou aplicativo. Estes Cookies armazenam informações referentes ao comportamento dos usuários, obtidas por meio da observação continuada dos seus hábitos de navegação, o que permite o desenvolvimento de um perfil específico para a veiculação personalizada de peças publicitárias.',
        'Cookies Próprios: São Cookies que são enviados ao dispositivo do usuário e gerenciadas exclusivamente pelo titular da página web para otimizar o seu funcionamento. As informações coletadas são utilizadas para melhorar a qualidade dos serviços prestados e a experiência do usuário. Estes Cookies permanecem no navegador por mais tempo, permitindo reconhecer o usuário recorrente de uma página web e desta forma, permitindo que o conteúdo seja adaptado de forma a oferecer aqueles que melhor se ajustam às suas preferências.',
        'Cookies Analíticos de Terceiros: Nosso Site também poderá utilizar o sistema Google Analytics, uma ferramenta de análise do Google que nos permite conhecer como os usuários interagem com o nosso Site. Sendo assim, habilita a utilização de Cookies no ambiente virtual em que o usuário se encontra para compilar informações de forma anônima e elaborar relatórios de tendências do Site, sem identificar os usuários de forma individual. Mais informações sobre os Cookies utilizados pelo Google Analytics podem ser encontradas no seguinte link: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=pt</a>',
      ],
    },
    {
      title: '5 - Quando concedo meu consentimento para o uso de cookies?',
      descriptions: [
        '5.1 - Quando o usuário acessa o nosso Site, antes de iniciar a navegação, este é informado sobre a utilizada de Cookies, de forma que este pode conceder o seu consentimento em relação à presente Política de Cookies indicando expressamente que “aceita”, fechando a janela informativa, continuando a navegação e/ou realizando ações dentro do Site que impliquem no seu consentimento tácito. Se o usuário continua a navegar pelo Site, isto pressupõe o seu consentimento com a presente Política de Cookies. Caso o usuário não esteja de acordo com o uso de Cookies, este deverá cessar a sua navegação no Site ou, poderá seguir as instruções do navegador que esteja utilizando para acessar o site, gerenciando ou desabilitando o uso de Cookies.',
      ],
    },
    {
      title: '6 - Como posso gerenciar e desabilitar os cookies?',
      descriptions: [
        '6.1 - O usuário poderá, a qualquer momento, gerenciar ou desabilitar os Cookies e continuar a navegar no Site, entretanto, o usuário precisa estar ciente de que a exclusão de Cookies poderá limitar e/ou impedir o seu acesso a determinadas seções e/ou serviços do Site. Adicionalmente, tal exclusão pode fazer com que o nosso sistema pense que o usuário nunca visitou o nosso Site, fato que poderá fazer com que o nosso aviso sobre a presente Política de Cookies apareça novamente.',
        '6.2 - O usuário poderá gerenciar ou desabilitar os Cookies por meio da configuração de seu navegador, o que lhe permitirá rejeitar a instalação de todos os apenas de alguns Cookies. A maioria dos navegadores permite avisar sobre a presença de Cookie e/ou recusá-los automaticamente.',
        '6.3 - A não ser que o usuário tenha ajustado as configurações do navegador, nosso sistema criará Cookies assim que você visitar nosso Site e aceitar a presente Política de Cookies. Lembre-se de que todos os navegadores de Internet permitem alterar estas configurações.',
        '6.4 - Caso o usuário utilize dispositivos diferentes para visualizar e acessar o nosso Site, este deverá se certificar de que o navegador de cada dispositivo está ajustado para atender suas preferências no que diz respeito à utilização de Cookies.',
        '6.5 - Para optar por não ser rastreado pelo Google Analytics, acesse o seguinte link: <a href="https://tools.google.com/dlpage/gaoptout?hl=pt">https://tools.google.com/dlpage/gaoptout?hl=pt</a>.',
        '6.6 - Para obter mais informações sobre as definições e configurações de Cookies, de acordo com o navegador sendo utilizando, recomendamos que acesse os seguintes links:',
        'Internet Explorer: <a href="https://support.microsoft.com/pt-br/help/260971/description-of-cookies">https://support.microsoft.com/pt-br/help/260971/description-of-cookies</a>',
        'Mozilla Firefox: <a href="http://support.mozilla.org/pt-BR/kb/cookies">http://support.mozilla.org/pt-BR/kb/cookies</a>',
        'Google Chrome: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR</a>',
        'Safari: <a href="http://support.apple.com/kb/PH5042">http://support.apple.com/kb/PH5042</a>',
        'Opera: <a href="https://help.opera.com/en/latest/web-preferences/">https://help.opera.com/en/latest/web-preferences/</a>',
      ],
    },
    {
      title: '7 - Fale conosco',
      descriptions: [
        '7.1 - Caso o usuário deseje realizar qualquer pergunta referente à presente Política de Cookies ou se tiver quaisquer outras questões relacionadas ao uso de suas informações, pode entrar em contato com a Resale por meio do seguinte endereço de e-mail: naoresponda@resale.com.br.',
      ],
    },
    {
      title: '8 - Fale conosco',
      descriptions: [
        '8.1 - A Resale pode atualizar a presente Política de Cookies a qualquer momento. Sendo assim, por favor, verifique esta página regularmente.',
        '8.2 - A presente Política de Cookies foi atualizada em agosto de 2020.',
      ],
    },
  ],
}

const dataDeletion = {
  lastUpdate: '21 de maio de 2021',

  info:
    'A RESALE TECNOLOGIA E SERVIÇOS S.A, está comprometida com a proteção de dados e informações pessoais que são compartilhados pelos usuários conforme definido abaixo. Essa política define como os dados são protegidos nos processos de coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e eliminação, para além da Lei no 13.709/2018 (Lei Geral de Proteção de Dados).',

  terms: [
    {
      title: '1 - Documentos que podem ser armazenados pela Resale',

      descriptions: [
        '1.1 - Carta de crédito',
        '1.2 - Certidão de casamento e averbações',
        '1.3 - Certidão de nascimento',
        '1.4 - Certidões negativas, fiscais, federais, trabalhistas, cíveis <e criminai',
        '1.5 - Comprovante de pagamento de condomínio',
        '1.6 - Comprovante de pagamento de IPTU',
        '1.7 - Comprovante de pagamento de ITBI',
        '1.8 - Comprovante de residência',
        '1.9 - Contrato social e a última alteração consolidada',
        '1.10 - CNH',
        '1.11 - CPF',
        '1.12 - Demonstrativo de pagamento de pensão, se aposentado o<u pensionist',
        '1.13 - Documento de declaração de origem de valores',
        '1.14 - E-mail (endereço eletrônico)',
        '1.15 - Extrato bancário',
        '1.16 - Extrato do FGTS',
        '1.17 - Foto Selfie',
        '1.18 - Holerite dos últimos meses',
        '1.19 - Imposto de renda do último exercício financeiro com o protocol<o de entreg',
        '1.20 - Matrícula do Imóvel obtida em base pública',
        '1.21 - RG',
        '1.22 - Telefone',
        '1.23 - Qualquer outro documento relacionado ao objeto da contratação.',
      ],
    },

    {
      title: '2 - Do direito a eliminação de dados',
      descriptions: [
        'O direito a eliminação de dados aparece no artigo 18 da Lei Geral de Proteção de Dados, que estabelece que o Usuário pode solicitar à Resale - Controladora, a qualquer momento, mediante requisição os seguintes tópicos:',
        '2.1 - Acesso e correção de dados incompletos, inexatos ou desatualizados',
        '2.2 - Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou em desconformidade com a LGPD',
        '2.3 - Portabilidade dos dados a outro fornecedor de serviços ou produtos',
        '2.4 - Revogação do consentimento	',
        '2.5 - Eliminação dos dados pessoais tratados com o consentimento do titular.',
        'Fato é que os itens acima tem uma ressalva. O artigo 16 enumera as hipóteses em que a eliminação dos dados pessoais não serão atendidas.',
        '<strong>Considerando ainda que os casos excepcionais são:</strong>',
        '<li>Cumprimento de obrigação legal ou regulatória pela Resale</li>',
        '<li>Estudo por órgão de pesquisa (garantida, sempre que possível, a anonimização dos dados pessoais)</li>',
        '<li>Uso exclusivo da Resale, vedado seu acesso por terceiro, e desde que anonimizados os dados</li>',
      ],
    },

    {
      title:
        '3 - Da disponibilização da matrícula do Imóvel obtida em base pública',
      descriptions: [
        'Quanto à matrícula, a Resale informa que, para o cumprimento de sua atividade principal, divulga informações relevantes sobre os imóveis contidos em sua Plataforma, em conformidade com o disposto no artigo 7o, inciso IX, §§ 3o e 4o, da Lei no 13.709/2018. Destaca-se que as informações disponibilizadas sobre os imóveis divulgados foram obtidas em bases públicas, para a finalidade específica de dar ciência ao potencial comprador sobre a real situação jurídica do imóvel, não devendo ser utilizadas por quaisquer dos interessados, em hipótese alguma, para outras finalidades.',
        'Ressalvamos, ainda, que a eliminação dos dados pessoais não será atendida quando importar na paralisação de divulgação da matrícula em nossa plataforma, isto, pois, conforme dispõe o princípio da publicidade do Direito Registral, a coleta, o armazenamento e o compartilhamento de dados inseridos na cadeia dominial do imóvel são inerentes ao exercício do direito de propriedade, não se aplicando a exceção prevista no inciso IX, do art. 7o da LGPD aos titulares cujos dados estejam inseridos na matrícula do imóvel.',
        'Portanto, em caso de solicitação de exclusão, a Resale irá analisar o caso através do e-mail: <strong>juridico@resale.com.br</strong>, observando que, quando forem referentes à disponibilização da matrícula, bem como necessários para o cumprimento de obrigações regulatórias ou legais do controlador, os dados não serão excluídos.',
      ],
    },

    {
      title:
        'Importante esclarecer, ainda, os prazos médios de conservação armazenamento:',

      descriptions: [
        '<li>05 anos para os dados relativos à gestão de pagamento</li>',
        '<li>10 anos para os dados fiscais (impostos, tributos e taxas)</li>',
        '<li>03 anos para os dados decorrentes do contrato de compra e venda</li>',
        '<li>06 meses para os dados de registro (log)</li>',
      ],
    },
  ],
}

export default { terms, privacy, cookies, dataDeletion }
