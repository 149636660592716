import { SALE_CAMPAIGNS } from './1_SaleCampaigns'

export const replaceCampaignToShow = enviriomentVariable => {
  if (SALE_CAMPAIGNS.blackoutDay.test(enviriomentVariable)) {
    return 'Blackout Day'
  }

  if (SALE_CAMPAIGNS.megaFeiraoEmgea.test(enviriomentVariable)) {
    return 'Mega Feirão Emgea'
  }

  if (SALE_CAMPAIGNS.feirao15.test(enviriomentVariable)) {
    return 'CUPOM Mega Feirão'
  }

  if (SALE_CAMPAIGNS.melhorProposta.test(enviriomentVariable)) {
    return 'Melhor Proposta'
  }

  if (SALE_CAMPAIGNS.cupomEmgeaOutubro10.test(enviriomentVariable)) {
    return 'CUPOM10'
  }

  if (SALE_CAMPAIGNS.cupomEmgeaOutubro20.test(enviriomentVariable)) {
    return 'CUPOM20'
  }

  if (SALE_CAMPAIGNS.esquentaBlackoutEmgea.test(enviriomentVariable)) {
    return 'Blackout'
  }
  if (SALE_CAMPAIGNS.blackout.test(enviriomentVariable)) {
    return 'Blackout'
  }

  if (SALE_CAMPAIGNS.saldaoBlackoutEmgea.test(enviriomentVariable)) {
    return 'SALDÃO'
  }
  return enviriomentVariable
}
