import React from 'react'
import classNames from 'classnames'
import { Image } from 'components'
import blackoutDayIcon from 'assets/temporary/blackout/BlackoutDay/icon/blackoutDay.png'
import { BlackoutIcon } from 'assets/temporary/esquentaBlackout'

export const getImagesCampaignMap = ({
  styles,
  isBlackout,
  isBlackoutDay,
}) => ({
  blackout: (
    <span
      className={classNames(styles.icon, {
        [styles.blackoutIcon]: isBlackout,
      })}
    >
      <Image source={BlackoutIcon} alt="Tag" />
    </span>
  ),
  blackoutDay: (
    <span
      className={classNames(styles.icon, {
        [styles.blackoutDayIcon]: isBlackoutDay,
      })}
    >
      <Image source={blackoutDayIcon} alt="Tag" />
    </span>
  ),
})
