import {
  banner_desktop,
  banner_mobile,
  banner_xLarge,
} from 'assets/images/home-banners'
import {
  bannersVariations,
  getImageBySize,
  getLinkBySize,
} from 'utils/bannersDictionary'

export const getBanners = ({ campaigns = {} }) => {
  const {
    HOME_MOBILE,
    HOME_NORMAL,
    HOME_LARGE,
    LISTING_NORMAL,
    LISTING_LARGE,
    LISTING_MOBILE,
    LISTING_X_LARGE,
  } = bannersVariations
  let listBanners = []
  let homeBanners = []

  if (Object.keys(campaigns).length) {
    Object.values(campaigns).forEach(
      ({ campaignKey, banners, isActive, isPreLaunch }) => {
        if (!banners?.length || (!isActive && !isPreLaunch)) return

        homeBanners.unshift({
          sourceMobile: getImageBySize(banners, HOME_MOBILE),
          sourceNormal: getImageBySize(banners, HOME_NORMAL),
          sourceLarge: getImageBySize(banners, HOME_LARGE),
          link:
            getLinkBySize(banners, HOME_MOBILE)?.url ||
            getLinkBySize(banners, HOME_NORMAL)?.url ||
            getLinkBySize(banners, HOME_LARGE)?.url,
          name: campaignKey,
          alt: `campaign ${campaignKey}`,
        })

        listBanners.unshift({
          sourceMobile: getImageBySize(banners, LISTING_MOBILE),
          sourceNormal: getImageBySize(banners, LISTING_NORMAL),
          sourceLarge: getImageBySize(banners, LISTING_LARGE),
          sourceXLarge: getImageBySize(banners, LISTING_X_LARGE),
          link:
            getLinkBySize(banners, LISTING_MOBILE)?.url ||
            getLinkBySize(banners, LISTING_NORMAL)?.url ||
            getLinkBySize(banners, LISTING_LARGE)?.url ||
            getLinkBySize(banners, LISTING_X_LARGE),
          name: campaignKey,
          alt: `campaign ${campaignKey}`,
        })
      }
    )
  }

  homeBanners = homeBanners.filter(
    ({ sourceNormal, sourceLarge, sourceMobile }) =>
      !!sourceNormal && !!sourceLarge && !!sourceMobile
  )

  if (!homeBanners?.length) {
    homeBanners.unshift({
      sourceMobile: banner_mobile,
      sourceNormal: banner_desktop,
      sourceLarge: banner_xLarge,
      link: `busca`,
      name: '',
    })
  }

  listBanners = listBanners.filter(
    ({ sourceNormal, sourceLarge, sourceMobile, sourceXLarge }) =>
      !!sourceNormal && !!sourceLarge && !!sourceMobile && !!sourceXLarge
  )
  return {
    homeBanners,
    listBanners,
  }
}
