import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import { BaseLayout } from 'templates'
import { PagesBanner, Text, Title } from 'components'
import { Helmet } from 'react-helmet'
import DOMPurify from 'dompurify'
import { usePageView } from 'hooks/useGAPageView'
import PropTypes from 'prop-types'
import { setUtms } from 'utils/Utms'
import termsDict, { getTerm, getParam } from './dict'
import data from './termData'
import styles from './Terms.module.scss'

const {
  TERMS_OF_USE,
  PRIVACY_POLICY,
  COOKIES_POLICY,
  DELETION_POLICY,
} = termsDict

const sanitizer = DOMPurify.sanitize

export default function Terms({ location: { search }, term }) {
  const [activeTerm, setActiveTerm] = React.useState(getTerm(term))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeTerm])

  useEffect(() => {
    setUtms(search)
  }, [search])

  const setTerm = option => {
    setActiveTerm(option)
    navigate(`/termos/${getParam(option)}`)
    window.scrollTo(0, 0)
  }

  const { info, terms, lastUpdate } = data[activeTerm]

  const renderTerm = () =>
    terms.map(({ title, descriptions }, index) => (
      <article key={index.toString()}>
        <Title type="h2" fontSize="large">
          {title}
        </Title>
        {descriptions.map((desc, idx) => (
          <p
            key={idx.toString()}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizer(desc, {
                ALLOWED_TAGS: ['a', 'strong', 'li'],
                ALLOWED_ATTR: ['href'],
              }),
            }}
          />
        ))}
      </article>
    ))

  usePageView()
  return (
    <>
      <Helmet>
        <title>Resale | Termos de Uso</title>
      </Helmet>
      <BaseLayout backgroundColor="white">
        <PagesBanner title="Termos de Uso" />
        <main className={styles.terms}>
          <div className={styles.linksContainer}>
            <Text
              onClick={() => setTerm(TERMS_OF_USE)}
              className={
                activeTerm === TERMS_OF_USE ? styles.activeLink : styles.link
              }
            >
              Termos e Condições de Uso
            </Text>

            <Text
              onClick={() => setTerm(PRIVACY_POLICY)}
              className={
                activeTerm === PRIVACY_POLICY ? styles.activeLink : styles.link
              }
            >
              Política de Privacidade
            </Text>

            <Text
              onClick={() => setTerm(COOKIES_POLICY)}
              className={
                activeTerm === COOKIES_POLICY ? styles.activeLink : styles.link
              }
            >
              Política de Cookies
            </Text>

            <Text
              onClick={() => setTerm(DELETION_POLICY)}
              className={
                activeTerm === DELETION_POLICY ? styles.activeLink : styles.link
              }
            >
              Política de Exclusão de Dados
            </Text>
          </div>
          <div className={styles.container}>
            <Text className={styles.initialText}>
              Última atualização:
              {lastUpdate}
            </Text>
            {info && (
              <Text fontSize="medium" type="h1" className={styles.initialText}>
                {info}
              </Text>
            )}
            {renderTerm()}
            <Text fontSize="medium" type="h1" className={styles.contactText}>
              <b>Paulo Nascimento - DPO</b>
              <br />
              <b>Contato:</b>
              {' privacidade@resale.com.br'}
            </Text>
          </div>
        </main>
      </BaseLayout>
    </>
  )
}

Terms.propTypes = {
  location: {
    search: PropTypes.string,
  },
  term: PropTypes.string,
}

Terms.defaultProps = {
  location: {
    search: '',
  },
  term: '',
}
